<template>
  <div class="page-wrap">
    <NGrid
      v-if="Array.isArray(materialData) && materialData.length"
      :x-gap="15"
      :cols="24"
    >
      <NGi :span="5">
        <NCard
          size="small"
          :segmented="{ content: 'hard' }"
          title="实训目录"
        >
          <div class="dir-content-wrap" :style="{ height: `${dirContentWrapHeight}px` }">
            <NTree
              class="material-dir"
              block-line
              default-expand-all
              :data="materialData"
              label-field="title"
              children-field="section_list"
              :selected-keys="selectedSectionkeys"
              @update:selected-keys="handleTreeNodeSelect"
            />
          </div>
        </NCard>
        <NCard
          v-if="isCourseDetail"
          size="small"
          :segmented="{ content: 'hard' }"
          title="考试"
          class="exam-list-card"
        >
          <div class="exam-list-wrap" :style="{ height: `${examListWrapHeight}px` }">
            <ul v-if="examList.length > 0">
              <li v-for="item in examList" :key="item.paper_id">
                <div
                  class="exam-item"
                  :class="{ current: currentPaperId === item.paper_id }"
                  @click="handleExamSelect(item.paper_id)"
                >{{ item.title }}</div>
              </li>
            </ul>
            <div v-else class="tips-txt">无考试内容</div>
          </div>
        </NCard>
      </NGi>
      <NGi :span="19">
        <NCard
          v-if="currentPaperId === 0"
          size="small"
          :segmented="{ content: 'hard' }"
          title="实训课件"
          style="max-height: calc(100vh - 30px); overflow-y: auto; overflow-x: hidden;"
        >
          <div
            v-if="!!currentSection.key"
            class="section-content"
          >
            <NTabs
              v-if="Array.isArray(currentSection.material_section_type_list) && currentSection.material_section_type_list.length > 0"
              :tab-style="{ padding: '10px 20px' }"
              v-model:value="currentModuleTab"
              :key="currentSection"
            >
              <NTabPane
                v-for="(item, index) in currentSection.material_section_type_list"
                :key="index"
                :name="index"
                :tab="item.title"
              >
                <ModuleContent
                  :module-data="item"
                />
              </NTabPane>
            </NTabs>
            <div v-else class="tips-txt section-empty">该节暂无内容</div>
          </div>
          <div v-else class="tips-txt">请选择节进行查看</div>
        </NCard>
        <NCard
          v-else
          size="small"
          :segmented="{ content: 'hard' }"
          title="查看试卷"
          style="max-height: calc(100vh - 30px); overflow-y: auto; overflow-x: hidden;"
        >
          <ExamContent
            :paper-data="currentPaperData"
          />
        </NCard>
      </NGi>
    </NGrid>
    <div v-else class="tips-txt">无相应内容展示</div>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import { useRoute } from 'vue-router';

  import ModuleContent from './components/ModuleContent.vue';
  import ExamContent from './components/ExamContent.vue';
  import PageLoading from '@/components/PageLoading/index.vue';

  import { GetTaskDetail } from '@/api/task.js';
  import { GetCourseDetail } from '@/api/course.js';
  import { getPaperDetail } from '@/api/paper.js';

  import {
    processCourseResData,
    processChapterList
  } from '@/views/course/build/components/process-course-res-data.js';

  import { resStatusEnum } from '@/enumerators/http.js';
  const { SUCCESS } = resStatusEnum;

  const route = useRoute();
  const loading = ref(false)

  const materialId = route.query.id;
  const courseId = route.query.cid;
  const isDetail = !!materialId;
  const isCourseDetail = !!courseId;
  const materialData = ref([]);
  const selectedSectionkeys = ref([]);
  const currentSection = ref({});
  const currentModuleTab = ref(0);
  const examList = ref([]);
  const currentPaperId = ref(0);
  const paperIdData = {};
  const currentPaperData = ref({});

  const handleTreeNodeSelect = ([key], [option]) => {
    if (!key) {
      return false;
    }
    switch (key.split('-')[0]) {
      case '1':
        currentSection.value = {};
        break;
      case '2':
        currentSection.value = option;
        break;
    }
    currentModuleTab.value = 0;
    currentPaperId.value = 0;

    selectedSectionkeys.value = [key];
  };
  const handleExamSelect = paperId => {
    currentPaperId.value = paperId || 0;
    currentSection.value = {};
    selectedSectionkeys.value = [];

    if (paperIdData[paperId]) {
      currentPaperData.value = paperIdData[paperId];
    } else {
      loading.value = true;
      getPaperDetail({ 'Paper[id]': paperId }).then(res => {
        if (res.code === SUCCESS) {
          if (Array.isArray(res.data.paper_exercises)) {
            res.data.paper_exercises = res.data.paper_exercises.map(item => {
              item.options = item.ques_select;
              item.answer = item.ques_answer;
              item.analysis = item.ques_analys;
              return item;
            });
          }
          paperIdData[paperId] = res.data;
          currentPaperData.value = paperIdData[paperId];
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    }
    
  };

  const dirContentWrapHeight = ref(0);
  const examListWrapHeight = ref(0);
  const updateNavHeight = () => {
    if (isCourseDetail) {
      const wholeHeight = window.innerHeight - 45 - 100 - 8;
      dirContentWrapHeight.value = wholeHeight * 7 / 10;
      examListWrapHeight.value = wholeHeight * 3 / 10;
    } else {
      dirContentWrapHeight.value = window.innerHeight - 30 - 53;
      examListWrapHeight.value = 0;
    }
  };
  onMounted(() => {
    updateNavHeight();
    window.addEventListener('resize', updateNavHeight);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateNavHeight);
  });

  const initData = () => {
    if (isDetail) { // 实训详情
      loading.value = true;
      GetTaskDetail({
        'Task[id]': materialId
      }).then(res => {
        if (res.code === SUCCESS) {
          materialData.value = processChapterList(res.data.chapter);
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    } else if (isCourseDetail) { // 课程详情
      loading.value = true;
      GetCourseDetail({
        'Course[id]': courseId,
        'Course[type]': 2
      }).then(res => {
        if (res.code === SUCCESS) {
          materialData.value = processChapterList(processCourseResData(res.data.list));
          examList.value = res.data.exam_list || [];
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    } else { // 实训预览
      const tempData = window.localStorage.getItem('MATERIAL_PREVIEW');
      materialData.value = tempData ? JSON.parse(tempData) : [];
    }
  };
  initData();
</script>

<style lang="less" scoped>
  .page-wrap {
    padding: 15px;
  }
  .tips-txt {
    color: #999;
    font-size: 16px;
  }

  .material-dir ::v-deep(.n-tree-node-content__text) {
    word-break: break-all;
  }

  .section-content {
    margin: -12px -16px;
  }
  .section-empty {
    padding: 10px 20px;
  }

  .exam-list-card {
    margin-top: 15px;
  }
  
  .dir-content-wrap, .exam-list-wrap {
    margin: -12px -16px;
    padding: 12px 16px;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .exam-list-wrap {
    ul, li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    li {
      padding: 3px 0;
    }

    .exam-item {
      line-height: 24px;
      padding: 0 8px;
      transition: background-color .3s;
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        background-color: #f3f3f3;
      }
      &.current {
        background-color: rgba(59,111,255,.1);
      }
    }
  }
</style>