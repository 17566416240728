export const teachingTypeEnum = {
  PPT: 1,
  VIDEO: 2,
  IMG: 3
};

export default {
  1: '讲义', 
  2: '视频',
  3: '图片'
}