<template>
  <div>
    <div
      v-if="Array.isArray(moduleData.type_source_list) && moduleData.type_source_list.length > 0"
      class="module-content"
    >
      <ResourceContent
        ref="resourceContentRef"
        :resource-data="resourceData"
      />
      <OperBar
        ref="operBarRef"
        class="oper-bar-wrap fixed"
        :module-title="moduleData.title"
        :current-resource="currentResource"
        :current-resource-processed-data="resourceData"
        :resource-list="moduleData.type_source_list"
        @current-resource-change="handleCurrentResourceChange"
        @pic-pre="handlePicPre"
        @pic-next="handlePicNext"
      />
    </div>
    <div v-else class="module-content-empty">该模块下无内容</div>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';

  import ResourceContent from './ResourceContent.vue';
  import OperBar from './OperBar.vue';
  import PageLoading from '@/components/PageLoading/index.vue';

  import { getTeachingDetail } from '@/api/teaching.js';
  import { getExerciseDetails } from '@/api/exercises.js';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import { resStatusEnum } from '@/enumerators/http.js';

  const props = defineProps({
    moduleData: {
      type: Object,
      default: () => {}
    }
  });

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const resourceContentRef = ref(null);
  const operBarRef = ref(null);

  const currentResource = ref({});
  const resourceData = ref({});
  /*
  resourceData = {
    resourceType: xxx,
    teachingType: xxx,
    contents: [
      { name: xx, ext: '.xx', url: 'xx', videoId: 'xx' },

      { title: '', options: ['A.XXX', 'B.XXX'], answer: 'A B', score: 'xx.xx', type: x, analysis: xx }
    ]
  }
  */
  const indexResourceStore = ref({});
  const clearStore = () => {
    indexResourceStore.value = {};
  };
  const storeResource = (key, value) => {
    indexResourceStore.value[key] = value;
  };
  const setResourceData = ({ resourceIndex, resourceType, teachingType, list }) => {
    const tempResource = {
      resourceType,
      teachingType
    };
    if (Array.isArray(list)) {
      switch (resourceType) {
        case resourceEnum.TEACHING_RESOURCE_TYPE:
          tempResource.contents = list.map(({
            file_name: name,
            extension: ext,
            file_url: url,
            video_id: videoId
          }) => ({
            name,
            ext,
            url,
            videoId
          }));
          break;
        case resourceEnum.EXERCISES_RESOURCE_TYPE:
          tempResource.contents = list.map(({
            title,
            ques_select: options,
            ques_answer: answer,
            score,
            type,
            ques_analys: analysis
          }) => ({
            title,
            options,
            answer,
            score,
            type,
            analysis
          }));
          break;
      }
    }
    resourceData.value = tempResource;
    storeResource(resourceIndex, tempResource);
  };
  const getResourceData = (data) => {
    const { data_type, source_id, title } = data;
    const resourceIndex = props.moduleData.type_source_list.indexOf(data);
    switch (Number(data_type)) {
      case resourceEnum.TEACHING_RESOURCE_TYPE:
        loading.value = true;
        getTeachingDetail({
          'Teaching[id]': source_id[0]
        }).then(res => {
          if (res.code === SUCCESS) {
            setResourceData({
              resourceIndex,
              resourceType: resourceEnum.TEACHING_RESOURCE_TYPE,
              teachingType: Number(res.data.type),
              list: res.data.source
            });
          }
        }).catch(err => {}).finally(() => {
          loading.value = false;
        });
        break;
      case resourceEnum.EXERCISES_RESOURCE_TYPE:
        loading.value = true;
        getExerciseDetails({
          'Material[ids]': source_id
        }).then(res => {
          if (res.code === SUCCESS) {
            setResourceData({
              resourceIndex,
              resourceType: resourceEnum.EXERCISES_RESOURCE_TYPE,
              list: res.data
            });
          }
        }).catch(err => {}).finally(() => {
          loading.value = false;
        });
        break;
    }
  };
  const handleCurrentResourceChange = data => {
    currentResource.value = data;
    const resourceIndex = props.moduleData.type_source_list.indexOf(data);
    const storedData = indexResourceStore.value[resourceIndex];
    if (!storedData) {
      getResourceData(data);
    } else {
      resourceData.value = storedData;
    }

    resourceContentRef.value && resourceContentRef.value.resetData();
  };

  watch(() => props.moduleData, value => {
    if (!currentResource.value.title) { // 设置默认展示的资源
      if (Array.isArray(value.type_source_list) && value.type_source_list.length) {
        handleCurrentResourceChange(value.type_source_list[0]);
      }
    }
  }, { immediate: true });

  const handlePicPre = () => {
    resourceContentRef.value.prePic();
  };
  const handlePicNext = () => {
    resourceContentRef.value.nextPic();
  };
</script>

<style lang="less" scoped>
  .module-empty {
    min-height: 500px;
    padding: 0 20px;
    color: #999;
    font-size: 16px;
  }

  .module-content {
    position: relative;

    &:hover .oper-bar-wrap {
      display: block;
    }
  }
  .oper-bar-wrap {
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 1;
    transform: translate(0,-50%);
    display: none;

    &.fixed {
      position: fixed;
      top: 18%;
      right: 35px;
      transform: translate(0,0);
    }
  }

  .module-content-empty {
    padding: 0 16px 12px;
    color: #999;
    font-size: 16px;
  }
</style>