<template>
  <div class="oper-bar" :class="{'bar-show': operBarShow}">
    <NSpace vertical :size="[0, 30]">
      <NDropdown
        :animated="false"
        placement="left-start"
        :options="dropdownOptions"
        :render-label="dropdownLabelRender"
        @select="handleSelectResource"
        @update:show="handleDropdownShowChange"
      >
        <NButton text>
          <NIcon :size="45"><ListAltOutlined /></NIcon>
          <div>文件目录</div>
        </NButton>
      </NDropdown>
      <NSpace vertical>
        <NButton text @click="handleClickPrePic" v-if="prePicShow">
          <NIcon :size="45"><ArrowBackCircleOutline /></NIcon>
          <div>上一页</div>
        </NButton>
        <NButton text @click="handleClickNextPic" v-if="nextPicShow">
          <NIcon :size="45"><ArrowForwardCircleOutline /></NIcon>
          <div>下一页</div>
        </NButton>
      </NSpace>
      <NButton text @click="toggleFullScreen">
        <template v-if="isFullScreen">
          <NIcon :size="45">
            <FullscreenExitOutlined />
          </NIcon>
          <div>退出全屏</div>
        </template>
        <template v-else>
          <NIcon :size="45">
            <FullscreenOutlined />
          </NIcon>
          <div>全屏显示</div>
        </template>
      </NButton>
    </NSpace>
  </div>
</template>

<script setup>
  import { h, ref, computed, onMounted, onBeforeUnmount } from 'vue';
  import { NSpace } from 'naive-ui';

  import { ListAltOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@vicons/material';
  import { ArrowBackCircleOutline, ArrowForwardCircleOutline } from '@vicons/ionicons5';

  import themeSettings from '@/settings/theme-settings.js';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import { teachingTypeEnum } from '@/enumerators/teaching-types-map.js';

  const operBarShow = ref(false);

  const emit = defineEmits([
    'current-resource-change',
    'pic-pre',
    'pic-next'
  ]);
  const props = defineProps({
    moduleTitle: {
      type: String,
      default: ''
    },
    currentResource: {
      type: Object,
      default: () => {}
    },
    currentResourceProcessedData: {
      type: Object,
      default: () => {}
    },
    resourceList: {
      type: Array,
      default: () => []
    }
  });

  const dropdownOptions = computed(() => {
    return [
      {
        type: 'group',
        label: `${props.moduleTitle}文件列表`,
        key: 'moduleTitle',
        children: props.resourceList.map((item, index) => ({
          label: item.title,
          key: String(index),
          resourceData: item
        }))
      }
    ]
  });

  const dropdownLabelRender = option => {
    if (option.type === 'group') {
      return h('div', { style: 'padding: 0 10px 8px; font-size: 15px; font-weight: bold; color: #333; text-align: center;' }, option.label);
    } else {
      const labelEls = [
        h('div', { title: option.label, style: 'white-space: nowrap; text-overflow: ellipsis; overflow-x: hidden;' }, option.label)
      ];
      if (option.resourceData === props.currentResource) {
        labelEls.push(
          h('span', { style: `position: absolute; top: 0; right: 10px; color: ${themeSettings.appThemeColor}` }, '（当前）')
        );
      }
      return h(
        'div',
        { style: 'width: 285px; padding: 0 65px 0 0;' },
        labelEls
      );
    }
  };
  const handleDropdownShowChange = b => {
    operBarShow.value = b;
  };
  const handleSelectResource = (key, { resourceData }) => {
    emit('current-resource-change', resourceData);

    currentPicIndex.value = 0;
  };

  const currentPicIndex = ref(0);
  const prePicShow = computed(() => {
    return currentPicIndex.value > 0;
  });
  const nextPicShow = computed(() => {
    const resource = props.currentResourceProcessedData;
    if (
      resource &&
      resource.resourceType === resourceEnum.TEACHING_RESOURCE_TYPE &&
      resource.teachingType === teachingTypeEnum.IMG
    ) {
      return currentPicIndex.value < (resource.contents.length - 1);
    } else {
      return false;
    }
  });
  const handleClickPrePic = () => {
    currentPicIndex.value--;
    emit('pic-pre');
  };
  const handleClickNextPic = () => {
    currentPicIndex.value++;
    emit('pic-next');
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen && document.exitFullscreen();
    }
  };
  const isFullScreen = ref(false);
  const fullScreenToggleHandler = () => {
    isFullScreen.value = !!document.fullscreenElement;
  };
  onMounted(() => {
    fullScreenToggleHandler();
    document.addEventListener('fullscreenchange', fullScreenToggleHandler);
  });
  onBeforeUnmount(() => {
    document.removeEventListener('fullscreenchange', fullScreenToggleHandler);
  });
</script>

<style lang="less" scoped>
  .oper-bar {
    text-align: center;

    ::v-deep(.n-button .n-button__content) {
      flex-direction: column;
    }

    &.bar-show {
      display: block;
    }
  }
</style>