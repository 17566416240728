import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function getVideoPlayAuth(data) {  
  return request({
    url: '/backend/web/upload/play-auth',
    method: POST,
    data
  });
}
