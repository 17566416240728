export const questionTypeEnum = {
  SINGLE_CHOICE: 1,
  MULTIPLE_CHOICE: 2,
  TRUE_FALSE: 3,
  OPEN: 4,
  FILL_BLANK: 5
};

export default {
  1: '单选',
  2: '多选',
  3: '判断',
  4: '开放',
  5: '填空'
}